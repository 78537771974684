<template>
  <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M7.718,1.517,9.26,5.587h3a.735.735,0,0,1,.518,1.283l-2.6,1.816L11.617,12a.786.786,0,0,1-1.119.982L7.011,11.02,3.525,12.981A.786.786,0,0,1,2.4,12L3.848,8.686,1.244,6.87A.734.734,0,0,1,1.76,5.587h3L6.3,1.517A.8.8,0,0,1,7.718,1.517Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.421,11,22.5,14.852a.375.375,0,0,1-.088.706l-6.394,1.279v5.538a.375.375,0,0,1-.684.212l-4.8-7.015"
    />
  </svg>
</template>
